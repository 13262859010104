const about = {
  about: [
    {
      title: "Creative",
    },
    {
      title: "Team Player",
    },
    {
      title: "Self learner",
    },
    {
      title: "Problem Solver",
    },
    {
      title: "Ager to learn new technologies",
    },
    {
      title: "Playing games or reading books in my free time",
    },
    {
      title: "Interested in trying various parts of Front-End web development",
    },
  ],
};

export default about;
